
const appTranslations = {
    en: {
        newTitle: "👋 Don't Forget Me!",
        originalTitle: "Michael's Portfolio",
    },
    pl: {
        newTitle: "👋 Nie Zapomnij O Mnie!",
        originalTitle: "Portfolio Michała",
    },
};

export default appTranslations;
