import MailIcon from "../img/mail.webp";
import LinkedinIcon from "../img/linkedin.webp";
import GitHubIcon from "../img/github.webp";
import ResumeIcon from "../img/resume.webp"; // Add an icon for the resume

const contactMeList = {
  title: {
    en: "Contact Me",
    pl: "Skontaktuj się ze mną"
  },
  misc: {
    email: {
      title: "Email",
      link: "mailto:woobe-studio@proton.me",
      icon: MailIcon,
    },
    linkedin: {
      title: "LinkedIn",
      link: "https://www.linkedin.com/in/wozniakdev/",
      icon: LinkedinIcon,
    },
    github: {
      title: "GitHub",
      link: "https://github.com/woobe-studio",
      icon: GitHubIcon,
    },
    resume: {  // New resume option with language-specific titles
      title: {
        en: "Resume",
        pl: "CV"
      },
      links: {  // Add language-specific links
        en: "/documents/resumeEnglish.pdf",  // Correct relative path for the English resume
        pl: "/documents/resumePolish.pdf",   // Correct relative path for the Polish resume
      },
      icon: ResumeIcon,
    },
  },
  contacts: {
    en: {
      email: {
        description: "Let's get in touch.",
      },
      linkedin: {
        description: "Let's connect.",
      },
      github: {
        description: "Check out my repos.",
      },
      resume: { // Add description for resume
        description: "Download my resume.",
      },
    },
    pl: {
      email: {
        description: "Skontaktuj się ze mną.",
      },
      linkedin: {
        description: "Połączmy się.",
      },
      github: {
        description: "Sprawdź moje repozytoria.",
      },
      resume: { // Add Polish description for resume
        description: "Pobierz moje CV.",
      },
    },
  }
};

export default contactMeList;
