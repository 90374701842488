// utils/languagesList.js
const languagesList = {
    title: {
        en: "Languages I Know",
        pl: "Języki, które znam"
    },
    languages: {
        en: [
            {
                title: 'English',
                proficiency: 'Advanced',
                description: 'I’m fluent in English and excel in both professional and personal communication skills.'
            },
            {
                title: 'Polish',
                proficiency: 'Native',
                description: 'Polish is my native language. I use it for all daily communication and professional interactions in my home country.'
            },
            {
                title: 'Russian',
                proficiency: 'Intermediate',
                description: 'I can communicate effectively in Russian, but my proficiency is not as strong as in English.'
            },
            {
                title: 'Spanish',
                proficiency: 'Elementary',
                description: 'I have basic communication skills in Spanish, with proficiency at an elementary level.'
            },
        ],
        pl: [
            {
                title: 'Angielski',
                proficiency: 'Zaawansowany',
                description: 'Jestem biegły w języku angielskim i wyróżniam się zarówno w umiejętnościach komunikacji zawodowej, jak i osobistej.'
            },
            {
                title: 'Polski',
                proficiency: 'Ojczysty',
                description: 'Polski jest moim językiem ojczystym. Używam go codziennie do komunikacji zarówno w życiu osobistym, jak i zawodowym.'
            },
            {
                title: 'Rosyjski',
                proficiency: 'Średniozaawansowany',
                description: 'Umiem się skutecznie komunikować po rosyjsku, ale moja znajomość tego języka jest słabsza w porównaniu do angielskiego.'
            },
            {
                title: 'Hiszpański',
                proficiency: 'Podstawowy',
                description: 'Posiadam podstawowe umiejętności komunikacyjne w hiszpańskim, z poziomem znajomości na poziomie podstawowym.'
            },
        ]
    }
};

export default languagesList;
