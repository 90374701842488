import techBadges from './techBadges';

// Define getSkills function before using it
const getSkills = (names) => {
  return techBadges
      .filter(badge => names.includes(badge.name))
      .map(badge => ({
        name: badge.name,
        color: badge.color,
        logo: badge.logo
      }));
};

const skillsTitle = { // Removed the extra colon
  en: {
    title: "My Skills",
    frontEnd: "FrontEnd",
    backEnd: "BackEnd",
    tools: "Tools",
  },
  pl: {
    title: "Moje Umiejętności",
    frontEnd: "FrontEnd",
    backEnd: "BackEnd",
    tools: "Narzędzia",
  }
};

const skills = {
  technologies: {
    frontEnd: getSkills([
      "HTML", "CSS", "TailwindCSS", "JavaScript", "React", "Bootstrap",
      "jQuery", "NextJs", "Dart", "Flutter", "Qt", "APIs", "OAuth2"
    ]),
    backEnd: getSkills([
      "Python", "Django", "Flask", "Dash", "Java", "NodeJS", "Kotlin",
      "C#", "R", ".NET", "C++"
    ]),
    tools: getSkills([
      "Git", "GitHub", "WebStorm", "VSCode", "IntelliJ", "Android Studio", "PyCharm",
      "Visual Studio", "STM32CubeIDE", "Neovim", "PowerShell", "Docker",
      "Gradle", "GitLab", "Linux", "Windows 11", "VirtualBox", "VMware",
      "MySQL", "MongoDB", "PostgreSQL", "SQLite"
    ]),
  }
};


export { skills, skillsTitle };
