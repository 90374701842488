import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import certificatesList from '../../utils/certificatesList';
import getUserLanguage from '../../utils/languageSelector';

const CertificateCard = ({ src, title, delay }) => (
    <motion.div
        className="overflow-hidden rounded-lg shadow-lg w-full transition-transform duration-200 ease-out transform hover:shadow-xl hover:scale-110"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.4, type: 'spring', stiffness: 200, delay }}
        whileHover={{ scale: 1.1, y: -3 }}
    >
        <img src={src} alt={title} className="w-full h-auto object-cover rounded-t-lg" />
        <div className="mt-4 text-center">
            <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
        </div>
    </motion.div>
);

const Certificates = () => {
    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const { ref: titleRef, inView: titleInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [language, setLanguage] = useState('en'); // Default to English

    useEffect(() => {
        const langCode = getUserLanguage();
        if (certificatesList.title[langCode]) {
            setLanguage(langCode);
        } else {
            setLanguage('en'); // Fallback to English if language not supported
        }
    }, []);

    // Get the certificates data based on the current language
    const certificates = certificatesList.certificates[language];

    return (
        <div
            id="certificates"
            className="relative px-4 py-16 mx-auto sm:px-6 md:px-12 lg:px-16 lg:py-20"
        >
            <div className="absolute top-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent via-gray-100 to-gray-200 opacity-90 shadow-md z-10"></div>

            <div
                ref={sectionRef}
                className="relative max-w-5xl mx-auto pt-16 pb-12 lg:pt-24 lg:pb-20"
            >
                <motion.div
                    ref={titleRef}
                    className="text-center mb-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
                    transition={{ duration: 0.8 }}
                >
                    <h2
                        className="text-3xl sm:text-4xl lg:text-4xl font-bold leading-tight text-gray-900"
                        style={{
                            fontFamily: 'Poppins, sans-serif',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <span className="relative inline-block">
                            <span className="relative" style={{ color: '#1E2A38' }}>
                                {certificatesList.title[language]}
                            </span>
                        </span>
                    </h2>
                </motion.div>

                <motion.div
                    className="flex flex-wrap justify-center gap-8 lg:gap-12"
                    initial="hidden"
                    animate={sectionInView ? "visible" : "hidden"}
                    variants={{
                        visible: {
                            opacity: 1,
                            transition: {
                                staggerChildren: 0.2, // Stagger the appearance of child elements
                            },
                        },
                        hidden: { opacity: 0 },
                    }}
                >
                    {certificates.map((cert, index) => (
                        <motion.div
                            key={index}
                            variants={{
                                visible: { opacity: 1, scale: 1 },
                                hidden: { opacity: 0, scale: 0.9 },
                            }}
                            transition={{ duration: 0.6, type: 'spring', stiffness: 300 }}
                            className="w-full sm:w-1/2 lg:w-1/3"
                        >
                            <CertificateCard
                                src={cert.imgSrc} // Use the dynamic image source
                                title={cert.title}
                                delay={index * 0.2} // Apply delay based on the index
                            />
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default Certificates;
