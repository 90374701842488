// components/Misc/TabInfo.js

function TabInfo(newTitle, originalTitle) {
    // Function to update the document title based on the tab's visibility
    const handleVisibilityChange = () => {
        if (document.hidden) {
            document.title = newTitle;  // Show new title when tab is inactive
        } else {
            document.title = originalTitle;  // Show original title when tab is active
        }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Set the original title initially when the tab is loaded
    document.title = originalTitle;

    // Clean up the event listener when the component is unmounted
    return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
}

export default TabInfo;
