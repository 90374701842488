const navbarList = {
    en: {
        aboutMe: "About Me",
        skills: "Skills",
        projects: "Projects",
        experience: "Experience",
        contactMe: "Contact Me"
    },
    pl: {
        aboutMe: "O mnie",
        skills: "Umiejętności",
        projects: "Projekty",
        experience: "Doświadczenie",
        contactMe: "Kontakt"
    }
};

export default navbarList;
