// components/Projects.js
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import projectsList from "../../utils/projectsList";
import techBadges from "../../utils/techBadges";
import DemoAccount from "../DemoAccount";
import GooglePlayImage from "../../img/google_play.webp";
import getUserLanguage from '../../utils/languageSelector'; // Import your language selector

const Badge = ({ name, color, logo }) => (
    <a
        href={`https://img.shields.io/badge/${encodeURIComponent(name)}-${color}?style=flat&logo=${logo}&logoColor=white`}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
    >
        <img
            src={`https://img.shields.io/badge/${encodeURIComponent(name)}-${color}?style=flat&logo=${logo}&logoColor=white`}
            alt={name}
            className="mb-1"
        />
    </a>
);

const ProjectCard = ({ project, delay }) => {
    const { ref: projectRef, inView: projectInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const language = getUserLanguage();
    const projectsData = projectsList[language];

    return (
        <motion.div
            ref={projectRef}
            className="border-4 border-gray-700 rounded-lg flex flex-col overflow-hidden transition-shadow duration-300 shadow-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: projectInView ? 1 : 0, y: projectInView ? 0 : 20 }}
            transition={{ duration: 0.6, delay }}
        >
            <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
                <img
                    src={project.image}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    alt={project.title}
                />
            </div>
            <div className="p-6 flex flex-col bg-white flex-1 text-center">
                <h1 className="text-2xl font-bold leading-6 mb-3" style={{ fontFamily: 'Poppins, sans-serif' }}>
                    {project.title}
                </h1>
                <h2 className="text-gray-600 mb-3 text-lg font-bold leading-5" style={{ fontFamily: 'Poppins, sans-serif' }}>
                    {project.subTitle}
                </h2>
                <p className="mb-3 text-gray-700">
                    {project.description}
                </p>

                {project.demoAccount && (
                    <DemoAccount demoAccount={project.demoAccount} />
                )}

                <div className="mt-4">
                    <h2 className="text-gray-600 mb-2 text-lg font-bold leading-5" style={{ fontFamily: 'Poppins, sans-serif' }}>
                        {projectsData.technologyTitle}
                    </h2>
                    <div className="border-t border-gray-300 mt-2 pt-2">
                        <div className="flex flex-wrap gap-1 justify-center">
                            {project.technologies.map((tech, index) => {
                                const badge = techBadges.find(b => b.name === tech);
                                if (badge) {
                                    return (
                                        <Badge
                                            key={index}
                                            name={badge.name}
                                            color={badge.color}
                                            logo={badge.logo}
                                        />
                                    );
                                }
                                return (
                                    <span
                                        key={index}
                                        className="px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-200 rounded-full"
                                    >
                                        {tech}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-300 my-4" />

                <div className="flex flex-col items-center space-y-2 mt-4">
                    {project.title === "Santes" && (
                        <a
                            href={project.googlePlay}
                            target="_blank"
                            rel="noreferrer"
                            aria-label="Google Play"
                            className="inline-flex items-center bg-transparent border-0 p-0"
                        >
                            <img
                                src={GooglePlayImage}
                                alt="Google Play"
                                className="w-40 h-auto cursor-pointer transition-transform transform hover:scale-105"
                            />
                        </a>
                    )}
                    {project.live && (
                        <a
                            href={project.live}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={projectsData.liveTitle}
                            className="inline-flex px-3 py-1 items-center transform font-semibold border transition duration-300 hover:scale-105 shadow hover:shadow-lg"
                        >
                            {projectsData.liveTitle}
                        </a>
                    )}
                    {project.github && (
                        <a
                            href={project.github}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={projectsData.sourceTitle}
                            className="inline-flex bg-blue-accent-200 hover:bg-blue-accent-700 shadow hover:shadow-lg px-3 py-1 items-center font-semibold transition duration-300 hover:scale-105 text-white"
                        >
                            {projectsData.sourceTitle}
                        </a>
                    )}
                </div>
            </div>
        </motion.div>
    );
};



const Projects = () => {
    const { ref: projectsRef, inView: projectsInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const language = getUserLanguage(); // Get the user's language
    const projectsData = projectsList[language];

    return (
        <div
            id="projects"
            className="relative flex justify-center items-center py-16"
            style={{background: 'linear-gradient(to right, #111828 0%, #111828 100%)'}}
        >
            <div className="absolute top-0 left-0 w-full h-24 z-10">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 283.5 19.6"
                    preserveAspectRatio="none"
                    className="w-full h-full opacity-33"
                >
                    <path
                        style={{fill: "#60646f"}}
                        d="M0 0L0 18.8 141.8 4.1 283.5 18.8 283.5 0z"
                    />
                    <path
                        style={{fill: "#94979e"}}
                        d="M0 0L0 12.6 141.8 4 283.5 12.6 283.5 0z"
                    />
                    <path
                        style={{fill: "#b8b9be"}}
                        d="M0 0L0 6.4 141.8 4 283.5 6.4 283.5 0z"
                    />
                    <path
                        style={{fill: "#ffffff"}} // White color
                        d="M0 0L0 1.2 141.8 4 283.5 1.2 283.5 0z"
                    />
                </svg>
            </div>

            <div className="relative z-11 flex flex-col items-center max-w-screen-xl px-4 mx-auto lg:px-8">
                <motion.div
                    ref={projectsRef}
                    className="text-center mb-10 lg:mb-12"
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: projectsInView ? 1 : 0, y: projectsInView ? 0 : 20}}
                    transition={{duration: 0.6}}
                >
                    <h2
                        className="text-4xl font-bold leading-none tracking-tight text-white mb-8 sm:text-4xl"
                        style={{fontFamily: 'Poppins, sans-serif'}}
                    >
                        <span className="relative inline-block">
                            <span className="relative">{projectsData.title}</span>
                        </span>
                    </h2>
                </motion.div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {projectsData.projects.map((project, index) => (
                        <ProjectCard
                            key={index}
                            project={project}
                            delay={index * 0.1}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};


export default Projects;
