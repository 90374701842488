import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const GoToTheTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const controls = useAnimation();

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            controls.start({ opacity: 1, scale: 1 });
        } else {
            controls.start({ opacity: 0, scale: 0.8 });
        }
    }, [isVisible, controls]);

    return (
        <motion.div
            className="fixed bottom-4 right-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={controls}
            transition={{ duration: 0.3 }}
            style={{
                zIndex: 14,
            }}
        >
            <div
                onClick={scrollToTop}
                className="flex justify-center items-center transition-transform transform hover:scale-110 bg-red-500 h-12 w-12 rounded-full cursor-pointer shadow-lg"
                style={{
                    fontSize: '1.5rem'
                }}
            >
                <i className="text-white fa-solid fa-chevron-up"></i>
            </div>
        </motion.div>
    );
};

export default GoToTheTop;
