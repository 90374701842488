import { useState, useRef, useEffect } from 'react';
import { handleScroll } from '../../utils/handleScroll';
import useAutoHide from 'auto-hide-hook';
import getUserLanguage from '../../utils/languageSelector';
import navbarList from '../../utils/navBarList'; // Adjust the path as necessary

const NavBar = () => {
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 360);
  const [isVisible, setIsVisible] = useState(false); // State for fade-in effect

  useAutoHide(menuRef, setIsMenuOpen);

  const toggleMenu = () => setIsMenuOpen(prev => !prev);

  // Update screen size state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 360); // Use 768px as the breakpoint for small screens
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Determine the user's language
  const userLang = getUserLanguage();
  const languageData = navbarList[userLang] || navbarList.en; // Default to English if the language is not found

  // Fade-in effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Adjust delay if needed

    return () => clearTimeout(timer);
  }, []);

  return (
      <div
          className={`relative z-10 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
          style={{
            background: 'linear-gradient(to right, #111828 0%, #111828 100%)',
            minHeight: '80px',
          }}
      >
        <div className='px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8'>
          <div className='relative flex items-center justify-between h-full'>
            <a
                href='/'
                aria-label='Woobe-Studio'
                title='Woobe-Studio'
                className='inline-flex items-center'
            >
              <i className='mr-2 text-3xl text-blue-accent-400 fa-solid fa-cubes'></i>
              <span
                  className={`ml-2 text-2xl lg:text-4xl font-bold tracking-wide text-white uppercase ${
                      isSmallScreen ? 'hidden' : 'block'
                  }`}
                  style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '800' }}
              >
              WOOBE-STUDIO
            </span>
              <span
                  className={`ml-2 text-2xl lg:text-2xl font-bold tracking-wide text-white uppercase ${
                      isSmallScreen ? 'block' : 'hidden'
                  }`}
                  style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '800' }}
              >
              WS
            </span>
            </a>
            {/* Toggle Button for Mobile Menu */}
            <button
                className='lg:hidden flex items-center p-2 text-white'
                onClick={toggleMenu}
                aria-label='Toggle menu'
            >
              <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'} text-3xl`}></i>
            </button>
            {/* Desktop Menu */}
            <ul className='items-center hidden space-x-6 lg:flex'>
              {Object.keys(languageData).map((key, index) => (
                  <li key={index}>
                    <a
                        href={`#${key.toLowerCase()}`}
                        onClick={handleScroll}
                        className='font-semibold tracking-wide text-white transition-colors duration-200 hover:text-blue-accent-200'
                        style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}
                    >
                      {languageData[key]}
                    </a>
                  </li>
              ))}
            </ul>
          </div>
          {/* Mobile Menu */}
          <div
              ref={menuRef}
              className={`fixed inset-y-0 right-0 bg-gradient-to-r from-[#111828] to-[#111828] text-white w-60 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}
              style={{ transition: 'transform 0.3s ease', zIndex: 10 }}
          >
            <div className='flex items-center justify-between p-4'>
              <span className='text-xl font-bold'>Menu</span>
              <button
                  className='text-3xl'
                  onClick={toggleMenu}
                  aria-label='Close menu'
              >
                <i className='fas fa-times'></i>
              </button>
            </div>
            <ul className='space-y-4 p-4'>
              {Object.keys(languageData).map((key, index) => (
                  <li key={index}>
                    <a
                        href={`#${key.toLowerCase()}`}
                        onClick={handleScroll}
                        className='block font-semibold text-white transition-colors duration-200 hover:text-blue-accent-200'
                        style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '600' }}
                    >
                      {languageData[key]}
                    </a>
                  </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  );
};

export default NavBar;
