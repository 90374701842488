// components/UnderConstructionNavbar.js
import { motion } from "framer-motion";
import getUserLanguage from "../../../utils/languageSelector";

const UnderConstructionNavbar = () => {
    // Define language-specific messages
    const messages = {
        en: "The site is under construction",
        pl: "Strona jest w budowie",
    };

    // Get user's language and corresponding message
    const userLanguage = getUserLanguage();
    const message = messages[userLanguage] || messages['en']; // Default to 'en' if language is not supported

    return (
        <motion.div
            className="fixed bottom-0 left-0 w-full bg-yellow-600 py-1 text-black text-center border-t-2 border-black"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            style={{ boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.2)', zIndex: 12 }}
        >
            <h1 className="text-xs md:text-sm font-semibold">
                {message} {/* Display localized message */}
            </h1>
        </motion.div>
    );
};

export default UnderConstructionNavbar;
