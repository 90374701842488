import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ isFadingOut }) => {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (isFadingOut) {
            const timer = setTimeout(() => {
                setShowMessage(true);
            }, 1000); // Match the fade-out duration

            return () => clearTimeout(timer);
        }
    }, [isFadingOut]);

    const loadingScreenStyle = {
        position: 'fixed',
        inset: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f7fafc',
        zIndex: 15,
        opacity: isFadingOut ? 0 : 1,
        transition: 'opacity 1s ease-out',
        pointerEvents: isFadingOut ? 'none' : 'all',  // Prevent interaction during fade-out
    };

    return (
        <div style={loadingScreenStyle}>
            {!showMessage ? (
                <div className="flex flex-col items-center text-center">
                    <div className="relative flex items-center justify-center w-24 h-24">
                        <div className="absolute w-24 h-24 border-4 border-t-4 border-transparent border-t-blue-600 rounded-full animate-spin"></div>
                    </div>
                    <h1 className="mt-6 text-3xl font-semibold text-gray-700">Loading...</h1>
                </div>
            ) : (
                <div className="flex flex-col items-center text-center">
                    <h1 className="mt-6 text-3xl font-semibold text-green-700">Loaded!</h1>
                </div>
            )}
        </div>
    );
};

export default LoadingScreen;
