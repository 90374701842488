// components/Experience.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import experienceData from '../../utils/experienceList';
import getUserLanguage from '../../utils/languageSelector'; // Ensure you have this utility

const ExperienceSection = ({ title, date, description, image, delay }) => {
    const { ref: expRef, inView: expInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <motion.div
            ref={expRef}
            className="flex flex-col items-center bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: expInView ? 1 : 0, y: expInView ? 0 : 20 }}
            transition={{ duration: 0.6, delay }}
        >
            <img
                src={image}
                alt={title}
                className="w-48 h-48 object-cover"
            />
            <div className="p-6 text-center">
                <h3
                    className="text-2xl font-semibold mb-2"
                    style={{ fontFamily: 'Poppins, sans-serif' }}
                >
                    {title}
                </h3>
                <p className="text-gray-500 mb-4">{date}</p>
                <p className="text-gray-700">{description}</p>
            </div>
        </motion.div>
    );
};

const Experience = () => {
    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: titleRef, inView: titleInView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const [language, setLanguage] = useState('en'); // Default to English

    useEffect(() => {
        // Determine the user's preferred language from the browser
        const langCode = getUserLanguage();
        // Set the language state based on whether it's available in experienceData
        if (experienceData.title[langCode]) {
            setLanguage(langCode);
        } else {
            setLanguage('en'); // Fallback to English if language not supported
        }
    }, []);

    const title = experienceData.title[language] || experienceData.title['en'];
    const experiences = experienceData.experiences[language] || experienceData.experiences['en'];

    return (
        <div
            id="experience"
            className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-20 lg:px-6 lg:py-16"
        >
            <motion.div
                ref={titleRef}
                className="max-w-xl mb-8 md:mx-auto text-center lg:max-w-2xl md:mb-10"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
                transition={{ duration: 0.6 }}
            >
                <h2
                    className="max-w-lg mb-4 font-sans text-3xl lg:text-4xl font-bold leading-tight tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
                    style={{
                        fontFamily: 'Poppins, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <span className="relative inline-block">
                        <span className="relative" style={{ color: '#1E2A38' }}>
                            {title}
                        </span>
                    </span>
                </h2>
            </motion.div>

            <motion.div
                ref={sectionRef}
                className="grid gap-6 row-gap-8 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1"
                initial={{ opacity: 0 }}
                animate={{ opacity: sectionInView ? 1 : 0 }}
                transition={{ duration: 0.8 }}
            >
                {experiences.map((experience, index) => (
                    <ExperienceSection
                        key={index}
                        title={experience.title}
                        date={experience.date}
                        description={experience.description}
                        image={experience.image}
                        delay={index * 0.2}
                    />
                ))}
            </motion.div>
        </div>
    );
};

export default Experience;
