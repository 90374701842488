// utils/aboutMeList.js
const aboutMeList = {
    title: {
        en: "About Me",
        pl: "O Mnie"
    },
    texts: {
        en: [
            "I am currently in my 3rd year of Computer Science at Białystok University of Technology. Since 2018, I have been involved in creating and developing software. I created the mobile game Santes and founded the student group Data Pigeons, which focuses on data analysis. This demonstrates my ability to create innovative solutions and work effectively in both software development and data science.",
            "In my free time, I enjoy biking, running, and working out. I also like exploring the Fallout series, experimenting with healthy food options, and diving into small DIY projects."
        ],
        pl: [
            "Obecnie jestem na 3. roku Informatyki na Politechnice Białostockiej. Od 2018 roku zajmuję się tworzeniem i rozwijaniem oprogramowania. Stworzyłem grę mobilną Santes oraz założyłem studencką grupę Data Pigeons, zajmującą się analizą danych, co pokazuje moją zdolność do tworzenia innowacyjnych rozwiązań oraz skutecznej pracy zarówno w zakresie programowania, jak i data science.",
            "W wolnym czasie lubię jeździć na rowerze, biegać i ćwiczyć. Lubię także odkrywać serię Fallout, eksperymentować z zdrowymi opcjami żywieniowymi i zagłębiać się w małe projekty DIY."
        ]
    }
};

export default aboutMeList;
